<template>
  <div class="tablePage">
    <router-link to="/" class="header">当前位置： 首页 / 我的基础练习</router-link>
    <a-form
      :model="where"
      :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
    >
      <a-row>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item label="病例名称:" labelAlign="right">
            <a-select
              v-model:value="where.caseId"
              show-search
              placeholder="请输入病例名称或选择"
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="(item, index) in roleNameData"
                :key="index"
                :label="item.caseName"
                :value="item.caseId"
                >{{ item.caseName }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item label="专业科目:" labelAlign="right">
            <a-select v-model:value="where.deptId" placeholder="全部">
              <a-select-option
                v-for="(item, index) in deptData"
                :key="index"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item class="ele-text-right" :wrapper-col="{ span: 24 }">
            <a-space>
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item label="关键词筛选:" labelAlign="right">
            <a-select
              v-model:value="where.keywords"
              show-search
              placeholder="全部"
            >
              <a-select-option
                v-for="(item, index) in keywordData"
                :key="index"
                :label="item"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-empty style="margin-top:40px;" v-show="list.length == 0" />
    <block v-for="(item, index) in list" :key="index">
      <item :itemData="item" />
    </block>
    <div class="foote">
      <a-pagination
        @change="handlePaginationChange"
        v-model:current="page"
        :total="total"
        show-less-items
        :show-total="(total) => `共${total}条数据`"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import item from "./modules/basicPractiseItem.vue";
export default {
  name: "MyBasicPractise",
  components: {
    item,
  },
  data() {
    return {
      page: 1,
      total: 0,
      list: [],
      // 表格搜索条件
      where: {},
      roleNameData: [],
      deptData: [],
      keywordData: [],
    };
  },
  created() {
    //获取table
    this.getTable();
    //获取专业科目
    this.getDept();
    //获取案例
    this.getRoleName();
    //获取关键词
    this.getKeywords();
  },
  methods: {
    filterOption(input,option){
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    async getTable() {
      try {
        let res = await this.$http.post(this.$interface.myExercise, {
          page: this.page,
          pageSize: 10,
          ...this.where,
        });
        let data = res.data || {};
        this.total = data.count;
        this.list = data.data || [];
      } catch (err) {}
    },
    async getDept() {
      try {
        let res = await this.$http.get(this.$interface.dictListDepartment);
        this.deptData = res.data.data || [];
      } catch (err) {}
    },
    async getRoleName() {
      try {
        let res = await this.$http.get(this.$interface.myExerciseSelect);
        this.roleNameData = res.data.data || [];
        console.log(this.roleNameData ,'this.roleNameData');
        if (this.roleNameData.length>0) {
         this.roleNameData = this.roleNameData.sort((a,b)=>{
            return a.caseName.split('-')[0]-b.caseName.split('-')[0]
          }) 
        }
      } catch (err) {}
    },
    async getKeywords() {
      try {
        let res = await this.$http.get(this.$interface.myExerciseKeywordsSelect);
        this.keywordData = res.data.data || [];
      } catch (err) {}
    },
    //分页改变
    handlePaginationChange(page, pageSize) {
      this.page = page;
      this.$nextTick(()=>this.getTable());
    },
    /* 搜索 */
    reload() {
      this.page = 1;
      this.$nextTick(()=>this.getTable());
    },
    /*  重置搜索 */
    reset() {
      this.where = {};
      this.page = 1;
      this.$nextTick(()=>this.getTable());
    },
  },
  computed: {
    ...mapGetters(["classIndex"]),
  },
  watch:{
    classIndex(val){
      //获取专业科目
      this.getDept();
      //获取案例
      this.getRoleName();
      //获取关键词
      this.getKeywords();
      this.reset();
    }
  }
};
</script>
<style lang="scss">
@import "index.scss";
</style>